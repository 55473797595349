///////////////////////////////////////////////////////
// Backoffice Pages Classes
///////////////////////////////////////////////////////

.content {
	position: relative;
	padding: 0px 0px 40px 0px;
	background: #eaeef3;
	overflow-y: auto;
	height: 100%;
}

.container {
	display: grid;
	align-items: center;
}

.elements {
	width: 80%;
	margin: auto;
}

//search
.search {
	max-width: 200px;
	max-height: 40px;
}

.search input {
	width: 200px;
	height: 40px;
}

//fitler
.filters {
	height: 36px;
	gap: 12px;
}

.btn-filter {
	height: 36px;
	color: #181818 !important;
	background: #ffffff;
	border: 0px;
}

.p-multiselect {
	display: flex;
	height: 36px;
	flex-wrap: wrap;
	align-content: center;
}

///////////////////////////////////////////////////////
// Table Classes
///////////////////////////////////////////////////////

.tableMarginTop {
	margin-top: 5px;
	padding: 0.5rem;
}

.withoutResultsSection {
	text-align: center;
	height: 85px;
	top: 88px;
	padding: 16px;
}

.p-paginator {
	background-color: transparent;
	justify-content: end;
	padding: 0;
	padding-top: 0.5rem;
}

.p-paginator .p-dropdown {
	margin-right: 0;
}

.p-datatable .p-paginator-bottom {
	border-width: 0;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
	color: var(--tpf-blue);
	background-color: #ffffff;
	color: var(--tpf-blue);
	background-color: #ffffff;
}

///////////////////////////////////////////////////////
// Sidebar Classes
///////////////////////////////////////////////////////

.p-sidebar {
	width: 600px;
	height: 100%;
}

.p-sidebar .p-sidebar-content {
	display: flex;
	flex-direction: column;
	background-color: #f9f9f9;
	border-bottom: 1px solid #ebebeb;
	padding: 0;
}

.fillParent {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.sidebarTabView .p-tabview-panel {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.sidebarTabView .p-tabview-panels {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.sidebarTabView .p-tabview {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.sidebarHeader {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 0;
}

.headerRow {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

.headerRow > h2 {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	text-align: center;
	color: #000000;
	// margin-bottom: 0;
}

.headerRow > h3 {
	font-size: 20px;
	font-weight: 400;
	line-height: 28px;
	text-align: center;
	color: #000000;
}

.sidebarFooter {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin: 0;
}

.footerRow {
	display: flex;
	align-items: center;
	justify-content: center;
	width: -webkit-fill-available;
	gap: 8px;

	.btn {
		height: 35px;
		gap: 8px;
		border-radius: 8px;

		&.btn-error {
			width: 132px;
			padding: 12px 9px 12px 9px;
		}

		&.btn-primary {
			min-width: 95px;
			padding: 12px 16px 12px 16px;
		}
	}
}

.footerRowLeft {
	display: flex;
	align-items: center;
	justify-content: start;
	width: -webkit-fill-available;
	gap: 8px;
}

.footerRowRight {
	display: flex;
	align-items: center;
	justify-content: end;
	width: -webkit-fill-available;
	gap: 8px;
}

// Sidebar content classes
.vehicleInfoSection {
	background: #f9f9f9;
	border-bottom: 1px solid #ebebeb;
	padding: 20px;
}

.sidebarDataSection {
	width: 100%;
	background-color: #f9f9f9;
	padding: 1.25rem;
	font-size: 14px;
	color: #000000;
}

.sidebarDataSection > table > tr > td > h3 {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: #000000;
	margin-block-start: 0px;
	margin-block-end: 0px;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-top: 4px;
	padding-bottom: 4px;
}

.sidebarDataSection > table > tr > .label > h3 {
	width: 144px;
}

.vehicleInfoSectionData {
	width: 100%;
	background-color: #f9f9f9;
}

.vehicleInfoSectionData > tr > td > h3 {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: #000000;
	margin-block-start: 0px;
	margin-block-end: 0px;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-top: 4px;
	padding-bottom: 4px;
}

.vehicleInfoSectionData > tr > .label > h3 {
	width: 144px;
}

.vehicleInfoSectionData > tr > .label {
	width: 144px;
}

.downloadVehicleDocsSection {
	display: flex;
	justify-content: end;
	padding-right: 13px;
}

.downloadVehicleDocsButton {
	background-color: transparent;
	color: #1f78d1;
	border: none;
	padding: 0;
	display: flex;
	align-items: center;
}

.downloadVehicleDocsButtonText {
	padding-left: 6px;
	font-size: 14px;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: -0.01em;
}

.downloadVehicleDocsButtonIcon {
	font-size: 20px !important;
}

///////////////////////////////////////////////////////
// Timeline Classes
///////////////////////////////////////////////////////

.sidebarTimeline {
	background: #f9f9f9;
	width: 600px;
	height: calc(100% - 48px);
	padding: 24px;
	overflow-x: hidden !important;
}

.vehicleInfoSectionMaintenanceTimeline {
	background: #f9f9f9;
	width: 600px;
	height: calc(100% - 48px);
	padding: 24px;
	border: 0px 0px 1px 0px;
	border-bottom: 1px solid #ebebeb;
	overflow-x: hidden !important;
}

.p-timeline .p-timeline-event-marker {
	border: 2px solid #d9d9d9;
	background-color: #f9f9f9;
}

.p-timeline-event-opposite {
	display: none;
}

.p-timeline-event-content {
	margin-bottom: 40px;
}

.vehicleInfoSectionMaintenanceTimelineTitle {
	color: #000000;
	margin-top: 0;
}

.vehicleInfoSectionMaintenanceTimelineDetails {
	color: #6e6e6e !important;
	margin: 0;
}

///////////////////////////////////////////////////////
// Tabs Classes
///////////////////////////////////////////////////////

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
	color: #000000;
	border-color: #0b68cb;
}

.p-tabview-nav {
	padding: 0px 16px 0px 16px;
}

.p-tabview .p-tabview-nav {
	border: 0;
	border-width: 0;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	border-color: transparent;
	padding: 12px 16px 12px 16px;
}

.p-tabview .p-tabview-nav li.p-highlight {
	border-bottom: 2px solid;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link .p-tabview-title {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.01em;
	color: #000000;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link .p-tabview-title {
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: -0.01em;
	text-align: left;
	color: #595959;
}

.p-tabview .p-tabview-panels {
	height: 100%;
	padding: 0;
}

///////////////////////////////////////////////////////
// Dialog Classes
///////////////////////////////////////////////////////

.p-dialog .p-dialog-header {
	height: 9.5vh;
	padding: 10px 24px 0px 24px;
	gap: 4px;
	border-radius: 8px 8px 0px 0px;
}

@media screen and (max-height: 850px) {
	.p-dialog .p-dialog-header {
		height: 14vh !important;
	}
}

.p-dialog .p-dialog-content {
	padding: 0 !important;
}

.p-dialog .p-dialog-footer {
	margin: 0;
	background-color: white !important;
	padding-top: 15px;
	border-top: 0.5px solid #ebebeb;
}

.p-dialog .p-dialog-footer button {
	float: right !important;
}

.dialogHeader {
	padding: 8px 8px 0px 8px;
	width: 340px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.dialogHeader > h4 {
	font-size: 22px;
	font-weight: 500;
	line-height: 33px;
	text-align: center;
	color: #162b2c;
	margin-bottom: 0;
	margin-top: 0;
}

.dialogMessage {
	padding-left: 32px;
	padding-right: 32px;
	width: 100%;
	height: 100%;
}

.alignFooterCenter {
	gap: 16px;
	display: flex;
	justify-content: center;
	margin: 0;
}

.footerButtons {
	padding: 8px;
	margin: 0;
	width: 118px;
}

///////////////////////////////////////////////////////
// Form Classes
///////////////////////////////////////////////////////

.newVehicleForm {
	padding: 17px 24px 17px 24px;
	background: #f9f9f9;
	border-bottom: 1px solid #ebebeb;
}

.newVehicleForm > span {
	display: flex;
	align-items: center;
	padding: 8px;
}

.newVehicleForm > span > label {
	width: 138px;
	height: 20px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: #000000;
	padding-right: 10px;
}

.newVehicleFormHeader > h2 :first-child {
	font-size: 20px;
	line-height: 28px;
	text-align: center;
	color: #000000;
}

.newVehicleFormHeader > h2 :last-child {
	font-size: 20px;
	font-weight: 400px;
	line-height: 28px;
	text-align: center;
	color: #000000;
}

.newVehicleForm > span > input {
	max-height: 28px;
}

.newVehicleForm > span > p-inputmask > input {
	max-height: 28px;
	min-width: 800px;
}

.newVehicleForm > span > input[type='tel'],
.newVehicleForm > span > input[type='number'],
.newVehicleForm > span > input[type='email'],
.newVehicleForm > span > input[type='text'] {
	min-width: 800px;
}

.newVehicleForm .p-multiselect {
	width: 800px;
	max-height: 28px;
}

.newVehicleForm span .p-multiselect {
	width: 800px;
	max-height: 28px;
}

// Edit vehicle Form
.editVehicleFormHeader {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 82px;
}

.editVehicleFormHeaderRow {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
}

.editVehicleFormHeaderRow > h2 {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
	text-align: center;
	color: #000000;
	margin-bottom: 0;
}

.editVehicleFormHeaderRow > h3 {
	font-size: 20px;
	font-weight: 400;
	line-height: 28px;
	text-align: center;
	color: #000000;
	margin-bottom: 0;
}

.editVehicleForm {
	min-width: 996px;
	padding: 17px 24px 17px 24px;
	background: #f9f9f9;
	border-bottom: 1px solid #ebebeb;
}

.editVehicleForm > span {
	display: flex;
	align-items: center;
	padding: 8px;
}

.editVehicleForm > span > label {
	width: 126px;
	height: 20px;
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: #000000;
}

.editVehicleForm > span > input {
	max-height: 28px;
}

.editVehicleForm span .p-multiselect {
	width: 800px;
	max-height: 28px;
}

.editVehicleForm > span > p-inputmask > input {
	max-height: 28px;
	min-width: 800px;
}

.editVehicleForm > span > input[type='tel'],
.editVehicleForm > span > input[type='number'],
.editVehicleForm > span > input[type='email'],
.editVehicleForm > span > input[type='text'] {
	min-width: 800px;
}

// Edit Vehicle Maintenance
.vehicleInfoSectionMaintenanceData .p-datatable .p-datatable-tbody > tr {
	background: #f9f9f9;
}

.vehicleInfoSectionMaintenanceData .p-datatable .p-datatable-thead > tr > th {
	font-size: 14px;
	font-weight: 600;
	line-height: 20px;
	color: #000000;
	border: 0;
	padding: 0;
	padding-top: 3px;
	padding-bottom: 3px;
}

.vehicleInfoSectionMaintenanceData .p-datatable .p-datatable-tbody > tr > td {
	border: 0;
	padding: 0;
	padding-top: 3px;
	padding-bottom: 3px;
}

// Edit vehicle maintenance form
.editVehicleMaintenance {
	min-width: 996px;
	padding: 17px 24px 17px 24px;
	background: #f9f9f9;
	border-bottom: 1px solid #ebebeb;
}

.editVehicleMaintenance > h2 {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	color: #0f1b2d;
	margin-top: 0;
	margin-bottom: 5px;
}

.editVehicleFormMaintenance > span {
	display: flex;
	align-items: center;
	gap: 50px;
}

.editVehicleFormMaintenance > span > input {
	max-height: 28px;
	width: 20%;
}

// .p-calendar {
// 	width: 130px;
// }

form .p-dropdown {
	width: 800px;
	max-height: 28px; // This line is causing the dropdown in home to be cut off
	align-items: center;
}

form .p-dropdown .p-dropdown-label {
	padding-top: 5px;
	padding-bottom: 5px;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
	padding: 8px 2px 8px 2px;
	border-radius: 4px;
	width: auto;
	height: 23px;
}

///////////////////////////////////////////////////////
// Toast Classes
///////////////////////////////////////////////////////

//toast
.p-toast-message-content {
	display: flex;
	align-items: center;
	height: 100%;
	color: #3b82f6;
}

.p-toast-icon-close {
	color: #3b82f6;
}

.p-toast-message-icon {
	display: flex;
	align-items: center;
	height: 32px;
}

.p-toast-message-icon {
	font-size: 24px;
}

///////////////////////////////////////////////////////
// Headers Classes
///////////////////////////////////////////////////////

// General classes
.headerContainer {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: start;
}

.titleContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 70%;
}

.titleContainer div {
	display: flex;
	white-space: nowrap;
	overflow: hidden;
	line-height: 1px;
	width: 520px;
}

.titleContainer div h2 {
	font-size: 20px;
	font-weight: 500;
	color: #000000;
	line-height: 1px; /* Ensure the height matches the text height */
	text-overflow: ellipsis;
}

.titleDeleteContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	width: 70%;
}

.titleDeleteContainer div {
	display: flex;
	white-space: nowrap;
	overflow: hidden;
	line-height: 1px;
	width: 100%;
}

.titleDeleteContainer div h2 {
	line-height: 1px; /* Ensure the height matches the text height */
	text-overflow: ellipsis;
}

///////////////////////////////////////////////////////
// Delete Dialog Classes
///////////////////////////////////////////////////////

.deleteContainer {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #f9f9f9;
	padding: 15px 23px 15px 23px;
	border-radius: 8px;
}

.deleteHeader {
	height: 25%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.deleteHeader h2 {
	font-weight: 500;
}

.deleteContent {
	height: 37.5%;
	width: 100%;
	display: flex;
	align-items: center;
}

.deleteFooter {
	height: 37.5%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 15px;
}

///////////////////////////////////////////////////////
// Other Classes
///////////////////////////////////////////////////////

.cb-tag {
	border-radius: 12px;
	padding: 2px 8px;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: -0.01em;
	text-align: center;
	color: #181818;

	&.active {
		background-color: #b1ebcd;
	}

	&.maintenance {
		background-color: #f9ebb5;
	}

	&.inactive {
		background-color: #fd947d;
	}
}

.loading-data {
	color: var(--tpf-gray-400);
}

.p-fileupload-choose {
	background-color: rgba(255, 255, 255, 0.5);
	height: 25px;
	width: 101px;
	border: 1px solid #1f78d1;
	color: #1f78d1;
	font-weight: 600;
	font-size: 14px;
	padding-left: 7.5px !important;
	margin: auto;
}

.p-fileupload-choose span {
	text-align: center;
	color: #1f78d1;
	font-weight: 600;
	font-size: 14px;
}

.p-fileupload-choose:hover {
	background-color: #1f78d1;
	color: white;
}

.p-fileupload-choose:hover span {
	color: white;
}

.file-names span {
	&:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.file-names {
	cursor: pointer;
	padding-left: 15px;
	display: flex;
	align-items: center;
}

.removeFiltersButton {
	background-color: transparent;
	color: #f03729;
	border: none;
	padding: 5px;
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.removeFiltersButtonText {
	padding-left: 6px;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
}

.removeFiltersButtonIcon {
	font-size: 14px !important;
}

.documentsTitle {
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	color: #000000;
	padding-bottom: 15px;
}

.downloadDocumentLabel {
	color: #000000;
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
}

.downloadDocumentPi {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	margin-right: 8px;
}

.downloadDocument {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
}

#openMapLink {
	margin-bottom: -8px;
}

#openMapLink a {
	text-decoration: underline;
	cursor: pointer;
}

.hexInput {
	width: 15% !important;
	margin-right: 5px;
}

.hide {
	display: none;
}

// Tooltip
.p-tooltip {
	margin-left: 85px;
}

.p-tooltip .p-tooltip-text {
	background-color: #64748b;
	color: white;
	font-size: 12px;
	font-weight: 400;
	line-height: 12px;
}

.p-tooltip.p-tooltip-top .p-tooltip-arrow {
	border-top-color: #64748b;
	left: 15px;
}

.tooltipIcon {
	color: white;
	font-size: 8px;
	background-color: #64748b;
	padding: 4px;
	border-radius: 50%; /* Add this line to make the background a circle */
	vertical-align: middle;
}

.requiredAsterisk {
	color: #bc2020;
	font-weight: 600;
	font-size: 14px;
}

// Multi select item comes highlited by default
.p-multiselect-panel
	.p-multiselect-items
	.p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus {
	background-color: transparent;
}

.p-multiselect-panel
	.p-multiselect-items
	.p-multiselect-item:not(.p-highlight):not(.p-disabled).p-focus:hover {
	background-color: #f3f3f3;
}

.p-multiselect-panel
	.p-multiselect-items
	.p-multiselect-item.p-highlight.p-focus {
	background-color: #eff6ff;
}

.navBarContent .content {
	height: 100%;
}

.export-button {
	border: 1px solid transparent !important;
	width: 100%;
	height: 100%;

	&:hover {
		background-color: var(--tpf-gray-100) !important;
		transition: 0.3s;
	}
}

.noBorderSection {
	background: #f9f9f9;
	padding: 20px;
}
