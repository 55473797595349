@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
	font-family: 'Poppins', 'sans-serif';
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
}

h1 {
	font-size: 34px;
	font-weight: 700;
	line-height: 51px;
	letter-spacing: 0em;
}

h2 {
	font-size: 22px;
	font-weight: 600;
	line-height: 33px;
	letter-spacing: 0em;
}

h3 {
	font-size: 18px;
	font-weight: 600;
	line-height: 27px;
	letter-spacing: 0em;
}

.secondary {
	font-size: 14px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
}

.button {
	font-size: 14px;
	font-weight: 600;
}

.secondary-button {
	font-size: 14px;
	font-weight: 400;
	line-height: 17px;
	letter-spacing: 0em;
}

.terciary {
	font-size: 10px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0em;
}

.formHeaderLeft {
	font-size: 20px;
	font-weight: 600;
	line-height: 28px;
}

.formHeaderRight {
	font-size: 20px;
	font-weight: 400;
	line-height: 28px;
}

.bold {
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
}

.normal-link {
	color: black;
	text-decoration: none;
}

.greyed {
	color: #64748b;
}
