.input::placeholder,
textarea::placeholder {
	font-size: 14px;
	line-height: 21px;
	color: #162b2c;
}

.p-password {
	width: 100%;
}

@media screen and (min-width: 768px) {
	.input {
		border-radius: 8px;
		height: 40px;
	}
}

//Profile input mask
@media screen and (min-width: 768px) {
	.inputMask > input {
		border-radius: 8px;
		height: 40px;
		width: 100%;
		border: 1px solid #d1d5db;
	}
}
@media screen and (max-width: 767.9px) {
	.inputMask > input {
		height: 48px;
		width: 100%;
	}
}

@media screen and (min-width: 768px) {
	.passwordInput > div > input {
		border-radius: 8px;
		height: 40px;
		width: 100%;
	}
}
@media screen and (max-width: 767.9px) {
	.passwordInput > div > input {
		height: 48px;
		width: 100%;
	}
}

.editableInput {
	font-weight: 400px;
	size: 16px;
	line-height: 24px;
	color: #64748b;
}
