:root {
	--tpf-white: #fcfcfc;
	--tpf-black: #031113;
	--tpf-blue: #1f78d1;
	--tpf-yellow: #f7a400;
	--tpf-green: #71852f;
	--tpf-pink: #f08ba9;
	--tpf-red: #e9500e;

	--tpf-gray-050: #f8fafc;
	--tpf-gray-100: #eaefef;
	--tpf-gray-200: #dadfdf;
	--tpf-gray-300: #f9f9f9;
	--tpf-gray-400: #9fa7a7;
	--tpf-gray-800: #444949;

	--tpf-blue-050: #d6f3ff;
	--tpf-blue-100: #6cc3e9;
	--tpf-blue-200: #009fe3;
	--tpf-blue-400: #0b7aaa;
	--tpf-blue-800: #033144;

	--tpf-green-050: #ffbb9e;
	--tpf-green-100: #ff773d;
	--tpf-green-200: #e9500e;
	--tpf-green-400: #9a370d;
	--tpf-green-800: #652003;

	--error-050: #fecdca;
	--error-100: #f97066;
	--error-200: #f03729;

	--warning-050: #fedf89;
	--warning-100: #fdb022;
	--warning-200: #f9841e;

	--success-050: #5bcd59 Copy;
	--success-100: #0aa174;
	--success-200: #039855;
}
