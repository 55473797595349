.btn-small {
	cursor: pointer;
	color: white;
	padding: 4 8 4 8px;
	border-radius: 8px;
	height: 23px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	justify-content: center;

	&.btn-primary {
		background-color: #1f78d1;

		&:hover {
			background-color: #114c87;
		}

		&:focus {
			background-color: #1f78d1;
			border: 1.5px solid #033144;
		}

		&:disabled {
			background-color: #a6d2ff;
		}
	}

	&.btn-secondary {
		border: 1px solid #1f78d1;
		background-color: #ffffff;
		color: #1f78d1;

		&:hover {
			background-color: #1f78d1;
			color: #ffffff;
		}

		&:focus {
			background-color: #114c87;
			border: 1.5px solid #1f78d1;
		}

		&:disabled {
			border: 1px solid #a6d2ff;
			background-color: #ffffff;
			color: #a6d2ff;
		}
	}

	&.btn-cancel {
		border: 1px solid #033144;
		background-color: #ffffff;
		color: #033144;

		&:hover {
			border: 1px solid #0b7aaa;
			background-color: #ffffff;
			color: #0b7aaa;
		}

		&:focus {
			border: 1.5px solid #009fe3;
			background-color: #ffffff;
			color: #0b7aaa;
		}

		&:disabled {
			background-color: #749ac0;
			color: #ffffff;
			border: 0px;
		}
	}

	&.btn-error {
		background-color: #ef4444;

		&:hover {
			background-color: #fd947d;
		}

		&:focus {
			background-color: #ef4444;
			border: 1.5px solid #ffb800;
		}

		&:disabled {
			background-color: #dadfdf;
		}
	}

	&.btn-success {
		background-color: #0aa174;

		&:hover {
			background-color: #32d583;
		}

		&:focus {
			background-color: #0aa174;
			border: 1.5px solid #039855;
		}

		&:disabled {
			background-color: #dadfdf;
		}
	}
}

::ng-deep p-button {
	height: 40px;
	border-radius: 8px;
}

.btn {
	cursor: pointer;
	color: white;
	padding: 8px;
	border-radius: 8px;
	border: none;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	justify-content: center;

	&.btn-primary {
		background-color: #1f78d1 !important;

		&:hover {
			background-color: #114c87;
		}

		&:focus {
			background-color: #1f78d1;
			border: 1.5px solid #033144;
		}

		&:disabled {
			background-color: #a6d2ff;
		}
	}

	&.btn-secondary {
		border: 1px solid #1f78d1;
		background-color: #ffffff !important;
		color: #1f78d1 !important;

		&:hover {
			background-color: #1f78d1;
			color: #ffffff;
		}

		&:focus {
			background-color: #114c87;
			border: 1.5px solid #1f78d1;
		}

		&:disabled {
			border: 1px solid #a6d2ff;
			background-color: #ffffff;
			color: #a6d2ff;
		}
	}

	&.btn-cancel {
		border: 2px solid #033144;
		background-color: #ffffff;
		color: #033144;

		&:hover {
			border: 1px solid #0b7aaa;
			background-color: #ffffff;
			color: #0b7aaa;
		}

		&:focus {
			border: 1.5px solid #009fe3;
			background-color: #ffffff;
			color: #0b7aaa;
		}

		&:disabled {
			background-color: #749ac0;
			color: #ffffff;
			border: 0px;
		}
	}

	&.btn-error {
		background-color: #ef4444;

		&:hover {
			background-color: #fd947d;
		}

		&:focus {
			background-color: #ef4444;
			border: 1.5px solid #ffb800;
		}

		&:disabled {
			background-color: #dadfdf;
		}
	}

	&.btn-success {
		background-color: #0aa174;

		&:hover {
			background-color: #32d583;
		}

		&:focus {
			background-color: #0aa174;
			border: 1.5px solid #039855;
		}

		&:disabled {
			background-color: #dadfdf;
		}
	}
}

.btn-large {
	cursor: pointer;
	color: white;
	padding: 8 16 8 16px;
	border-radius: 8px;
	//border: none;
	height: 48px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	justify-content: center;

	&.btn-primary {
		background-color: #1f78d1;

		&:hover {
			background-color: #114c87;
		}

		&:focus {
			background-color: #1f78d1;
			border: 1.5px solid #033144;
		}

		&:disabled {
			background-color: #a6d2ff;
		}
	}

	&.btn-secondary {
		border: 1px solid #1f78d1;
		background-color: #ffffff;
		color: #1f78d1;

		&:hover {
			background-color: #1f78d1;
			color: #ffffff;
		}

		&:focus {
			background-color: #114c87;
			border: 1.5px solid #1f78d1;
		}

		&:disabled {
			border: 1px solid #a6d2ff;
			background-color: #ffffff;
			color: #a6d2ff;
		}
	}

	&.btn-cancel {
		border: 1px solid #033144;
		background-color: #ffffff;
		color: #033144;

		&:hover {
			border: 1px solid #0b7aaa;
			background-color: #ffffff;
			color: #0b7aaa;
		}

		&:focus {
			border: 1.5px solid #009fe3;
			background-color: #ffffff;
			color: #0b7aaa;
		}

		&:disabled {
			background-color: #749ac0;
			color: #ffffff;
			border: 0px;
		}
	}

	&.btn-error {
		background-color: #ef4444;

		&:hover {
			background-color: #fd947d;
		}

		&:focus {
			background-color: #ef4444;
			border: 1.5px solid #ffb800;
		}

		&:disabled {
			background-color: #dadfdf;
		}
	}

	&.btn-success {
		background-color: #0aa174;

		&:hover {
			background-color: #32d583;
		}

		&:focus {
			background-color: #0aa174;
			border: 1.5px solid #039855;
		}

		&:disabled {
			background-color: #dadfdf;
		}
	}
}
