@import 'theme/buttons.scss';
@import 'theme/typography.scss';
@import 'theme/colors.scss';
@import 'theme/input.scss';
@import 'theme/backoffice.scss';
@import 'theme/dashboard.scss';

:root {
	--custom-grey: #eaeef3;
}
body,
html {
	margin: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

eyeicon,
eyeslashicon {
	color: #1f78d1 !important;
}

.p-datatable-wrapper {
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}

#pn_id_3 > p-paginator div {
	justify-content: end;
}

th {
	font-weight: bold;
}

.p-card {
	box-shadow: none !important;
}

.navbar-notification {
	.fa-bell {
		font-size: 16px;
		.p-badge {
			width: 10px;
			min-width: 10px;
			height: 10px;
			background-color: #f03729;
			transform: translate(85%, -85%);
		}
	}
}

.vehicleInfoSection {
	border-bottom: 0px;
}
